// スマホだと2回タップしないとリンク先に飛ばない問題の対策
window.ontouchstart = function() {};
window.addEventListener('touchstart', function() {}, true);
window.addEventListener('touchstart', function() {}, false);

// header
$(window).scroll(function () {
  if($(window).scrollTop() > 20) {
    $('.header').addClass('scroll-header');
  } else {
    $('.header').removeClass('scroll-header');
  }
});

// Menu
var state = false;
var pos;
$(".header-menu-btn").on("click",function(){
  if (state == false) {
    pos = $(window).scrollTop();
    $('body').addClass('fixed').css({'top': -pos});
    $(".header-nav").addClass('active');
    $(".header-menu-btn-inner").addClass('active');
    state = true;
  } else {
    $('body').removeClass('fixed').css({'top': 0});
    window.scrollTo(0, pos);
    $(".header-nav").removeClass('active');
    $(".header-menu-btn-inner").removeClass('active');
    state = false;
  }

  $("#menu").toggleClass("open");
});

// Cookie
$(document).ready(function() {
  const a = "cookieAgreeJP";
  let s = document.querySelector(".cookie-consent"),
    l = document.querySelector(".cookie-agree"),
    m = document.querySelector(".cookie-disagree");
  const d = localStorage.getItem(a);

  if (null != d) {
    const e = JSON.parse(d);
    if ("true" == e.value) setTimeout(c, 1e3);
    else {
      (new Date).getTime() > e.expire && (r(a, "true", 365), setTimeout(c, 1e3))
    }
  } else r(a, "true", 365), setTimeout(c, 1e3);

  function r(e, t, i) {
    const n = {
      value: t,
      expire: i = (new Date).getTime() + 24 * i * 3600 * 1e3
    };
    localStorage.setItem(e, JSON.stringify(n))
  }

  function c() {
    s.classList.add("is-show")
  }

  l.addEventListener("click", () => {
		s.classList.remove("is-show"), r(a, "false", 365)
	});
  m.addEventListener("click", () => {
		s.classList.remove("is-show"), r(a, "false", 365)
	});

});

// アニメーション
// ページ読み込み時
$(window).on('load', function(){
  
  $.when(
    $("#splash_logo").delay(1200).fadeOut('slow'),
    $("#splash").delay(2400).fadeOut(0)
  ).done(function(){
    stepInKvText();
    zoomOutKv();
    stepKvBlock()
  ;});
  
  slideInLead();
});

// KVのコピー
function stepInKvText() {
  const element = $('.kv-text-item-inner');
  const delayTime = 200;
  element.each(function (i) {
    $(this).delay(i * delayTime).queue(function () {
      $(this).addClass('stepIn').dequeue();
    });
  });
}

// TOPのKV
function zoomOutKv() {
  $('.kv-image-item').addClass('zoomOut').dequeue();
}

// 下層のリード文
function slideInLead() {
  $('.lower-kv-lead').delay(400).queue(function(){
    $(this).addClass('slideIn').dequeue();
  });
}

// スクロールアニメーション
let fadeupEffect = gsap.utils.toArray(".fadeInUp");
fadeupEffect.forEach((target) => {
  gsap.fromTo(  // fromTo アニメーション開始時と終了時の状態を指定
    target, 
    { // アニメーション開始時の状態
      y: 50, 
      autoAlpha: 0,
    },
    { // アニメーション終了時の状態
      y: 0,
      autoAlpha: 1,
      duration: 1,
      ease: "power4.out",
      scrollTrigger: {
        trigger: target,
        start: "top 70%",
        end: "bottom 10%",
      },
    }
  );
});

// TOPのabout usとservice
function stepKvBlock() {
  let fadeInItems = document.querySelectorAll('.fadeIn');
  fadeInItems.forEach((fadeInItem) => {
    ScrollTrigger.create({
      trigger: fadeInItem,
      start: "top bottom-=300px",
      toggleClass: {
        targets: fadeInItem,
        className: "anime",
      },
      once: true,
    });
  });
}

// 慣性スクロール（Lenis)
const lenis = new Lenis({
  lerp: 0.4,
  duration: 1,
});

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);


$(function() {
  // #で始まるアンカーリンクをクリックした時
  $('a[href^="#"]').on('click', function() {
 
    // href属性の値を取得
    var href = $(this).attr("href");
    // 移動先を取得
    var target = $(href == "#" || href == "" ? 'html' : href);
    // 移動先のポジション調整
    var adjust = 0;
    // 移動先ポジションの決定
    var position = target.offset().top + adjust;
 
    // スクロールの速度
    var speed = 500;
 
    // スクロールのタイプ
    var type = 'swing';
     
    // animate関数でスムーススクロール
    $('html, body').animate(
      {scrollTop:position}, 
      speed,
      type
    );
    return false;
  });
});
